<template>
  <base-layout>
    <section class="descriptor">
<!--      <img class="descriptor-logo-mob" src="../assets/img/landing-descriptor-rectangle-mob.png" alt="Лого">-->
      <div class="descriptor-wrapper">
        <div class="button-block">
<!--          <a @click.prevent="showReceiptPopup" class="landing-button register-receipt" href="#">Зарегистрировать чек</a>-->
          <Apmcheck
              :apmApiKey="key"
              :currentPath="'main'"
          />

          <a @click.prevent="showLoginPopup" class="landing-button login-button" href="#">Личный кабинет</a>
<!--          <a class="landing-button tg-bot" href="https://t.me/DobryAcademiaBot">Telegram bot</a>-->
        </div>
      </div>
    </section>
    <receipt-popup v-show="getPopupStatus('receipt')" />
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import ReceiptPopup from "@/components/popups/ReceiptPopup"
import Apmcheck from "@/components/Apmcheck"
import { VueAgile } from 'vue-agile'
import {
  descriptorOptions,
  certificatesOptions,
  prizesOptions,
  productsOptions,
  merchOptions,
  instrOptions,
  carousel
} from '@/carousel/config'
import {capitalize} from "@/utils/helpers";
import store from "@/store";

export default {
  name: "Main",
  components: {
    BaseLayout,
    ReceiptPopup,
    Apmcheck
  },

  data() {
    return {
      descriptorOptions,
      certificatesOptions,
      prizesOptions,
      productsOptions,
      merchOptions,
      instrOptions,
      carousel,
      key: "abb82268-7ffb-4227-a220-e198536fe835",
    }
  },

  methods: {
    getSvgImgUrl(image) {
      return require(`../assets/svg/${image}`)
    },

    getPngImgUrl(image) {
      return require(`../assets/img/${image}`)
    },

    getPopupStatus(name) {
      return this.$store.state.bus.popups[`is${capitalize(name)}Opened`]
    },

    showLoginPopup() {
      if (this.$store.getters["user/isLoggedIn"]) {
        this.$router.push("/personal/profile")

        return
      }

      this.$store.dispatch("bus/openPopup", "login")
    },

    showCurrentSlide(slide) {
      var list;
      document.querySelectorAll(".prizes-carousel").forEach((el) => {
        el.classList.remove('slide0');
        el.classList.remove('slide1');
        el.classList.remove('slide2');
        el.classList.remove('slide3');
        el.classList.remove('slide4');
        el.classList.add('slide' + slide.currentSlide);
      });

    }
  },
  async mounted() {
    if (this.$route.query.registerPopup) {
      this.$store.dispatch("bus/openPopup", "register")
    }

    const authKey = this.$route.query.auth_key

    if (authKey !== undefined) {
      localStorage.setItem("token", authKey)

      await store.dispatch("user/setToken", authKey)
      const response = await store.dispatch("user/setProfile")

      if ("error" in response && response.error === 1) {
        return
      }

      this.$router.push("/profile/personal")
    }
  }
}
</script>

<style>
.agile__nav-button:hover {
  cursor: pointer;
}

.agile__dots {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.merch img {
  max-height: 200px;
}

.what-to-do .agile__dots {
  bottom: -160px;
}

.products .agile__dots {
  bottom: -200px;
}

.prizes .agile__dots {
  bottom: -170px;
}

.prizes-block-1 .agile__dots {
  bottom: -290px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color:transparent;
}

.agile__dot--current {
  width: 35px;
}

.descriptor-carousel .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.descriptor-carousel .agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.descriptor-carousel .agile__nav-button--next {
  transform: rotate(180deg);
}

.descriptor-carousel .agile__actions {
  justify-content: center;
}

.prizes-block-3 .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.prizes-block-3 .agile__nav-button:after {
  content: url('../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.prizes-block-3 .agile__nav-button--next {
  transform: rotate(180deg);
}

.prizes-block-3 .agile__actions {
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 75px;
}

.products .agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.products .agile__nav-button:after {
  content: url('../assets/svg/arrow-white.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.products .agile__nav-button--next {
  transform: rotate(180deg);
}

.products .agile__actions {
  justify-content: space-around;
  position: absolute;
  width: 100%;
  bottom: 175px;
}

@media screen and (max-width: 992px) {
  .descriptor-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    width: 100%;
  }

  .what-to-do .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .what-to-do .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .what-to-do .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .what-to-do .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .merch .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .merch .agile__nav-button:after {
    content: url('../assets/svg/arrow-white.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .merch .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .merch .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .certificates-carousel .agile__nav-button {
    border: none;
    background-color: transparent;
    position: relative;
    height: 50px;
    width: 50px;
    color: transparent;
    margin: 0 5px 0 5px;
  }

  .certificates-carousel .agile__nav-button:after {
    content: url('../assets/svg/arrow-yellow.svg');
    position:absolute;
    left: 0;
    top: 0;
  }

  .certificates-carousel .agile__nav-button--next {
    transform: rotate(180deg);
  }

  .certificates-carousel .agile__actions {
    justify-content: space-between;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    width: 100%;
  }

  .prizes-carousel .agile__actions {
    bottom: 50%;
  }

  .products .agile__actions {
    justify-content: space-between;
  }
}

.agile__slides {
  align-items: flex-start;
}

.merch .agile__slides {
  align-items: baseline;
}

</style>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.monthly-prizes {
  position: absolute;
  background-color: #fff;
  border-radius: 40px;
  top: 27%;
  left: 50%;
  padding: 0px 45px;
  transform: translateX(-50%);
  text-align: center;
  width: 38%;
  //z-index: 2;
  .monthly-prizes-block {
    display: inline-block;
    width: 42%;
    float:left;
    img {
      width: 100%;
    }
  }
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: $green;
}

h2 {
  font-size: 30px;
  font-weight: 500;
}



.descriptor, .instruction, .prizes-block-1, .prizes-block-2, .prizes-block-3, .products {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-button {
  padding: 0 20px 0 20px;
  box-sizing: border-box;
  font-weight: 400;
  text-align: center;
  border-radius: 56px;
  white-space: nowrap;
}

.button-block {
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 0;
  padding-top: 200px;
}

.register-receipt, .login-button {
  position: relative;
  height: 80px;
  font-size: 18px;
  line-height: 80px;
  color: #fff;
  background-color: #3F3F3F;
}

.login-button {
  background-color: #BABABA;
}

.tg-bot {
  position: relative;
  height: 80px;
  font-size: 18px;
  line-height: 80px;
  color: $white;
  background-color: $purple;

  &::before {
    content: "";
    position: absolute;
    width: 35px;
    height: 33px;
    top: 22px;
    left: 18px;
    background-image: url("../assets/svg/tg.svg");
  }
}

.steps {
  margin: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 45px;
  font-size: 30px;
  color: $white;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    width: 400px;
    padding-top: 5px;
    padding-left: 67px;

    &::before {
      content: "";
      position: absolute;
      width: 53px;
      height: 53px;
      top: 0;
      left: 0;
      display: block;
    }

    &:nth-child(1) {
      margin-right: 6%;

      &::before {
        background-image: url("../assets/svg/number_one.svg");
      }
    }

    &:nth-child(2) {
      margin-right: 4%;

      &::before {
        background-image: url("../assets/svg/number_two.svg");
      }
    }

    &:nth-child(3) {
      margin-right: 6%;

      &::before {
        background-image: url("../assets/svg/number_three.svg");
      }
    }
  }
}

.descriptor {
  height: 900px;
  //background-image: url("../assets/img/landing-descriptor-rectangle.png");
  background-color: #fff;
}

.descriptor-logo-mob {
  display: none;
}

.descriptor-carousel {
  margin-top: 20px;
  padding-right: 10%;
  text-align: right;

  & > * {
    width: 400px;
    display: inline-block;
  }

  img {
    height: 170px;
  }
}

.instruction {
  position: relative;
  height: 875px;
  margin-top: -95px;
  background-image: url("../assets/svg/landing-purple-rectangle.svg");
  padding-left: 3%;
  padding-right: 3%;

  h1 {
    padding: 90px 0 50px 0;
    color: $white;
  }

  .yellow-thunderbolt-label {
    top: 60px;
    left: 115px;
  }

  .yellow-thunderbolt-label-mob {
    display: none;
  }

  .heart-label {
    top: 60px;
    right: 120px;
  }
}

.what-to-do {
  //display: flex;
  //justify-content: center;
  //column-gap: 80px;
}

.what-to-do-block {
  text-align: center;

  h2 {
    margin: 23px 0 10px 0;
    color: $yellow;
  }

  p {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    color: $white;
  }
}

.more-points {
  height: 150px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 0 60px 0 60px;
  display: flex;
  max-width: 1436px;
  justify-content: space-around;
  align-items: center;
  -moz-column-gap: 30px;
  column-gap: 30px;
  background-image: url("../assets/svg/landing-white-rectangle.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.want-more-points {
  font-size: 27px;
  font-weight: 400;
  color: $purple;
}

.more-points-link {
  height: 80px;
  padding: 0 35px 0 35px;
  font-size: 20px;
  font-weight: 400;
  line-height: 80px;
  background-color: $purple;
  color: $white;
  border-radius: 60px;
  white-space: nowrap;
}

.prizes {
  max-width: 1500px;
  margin: 0 auto;
}

.prizes-block-1, .prizes-block-2, .prizes-block-3 {
  margin: 20px 0% 20px 0%;
  padding-top: 30px;
  background-size: contain;
  background-position: center;

  h2 {
    margin-top: 50px;
  }
}

.prizes-block-1 {
  min-height: 625px;
  background-image: url("../assets/svg/landing-yellow-rectangle-prize.svg");
  margin: 20px 1% 20px 1%;
  h2 {
    color: $purple;
  }
}

.prizes-block-2 {
  min-height: 555px;
  margin: 20px 1% 20px 1%;
  background-image: url("../assets/svg/landing-pink-rectanlge-prize.svg");

  h2 {
    padding: 0 15% 0 15%;
    color: $white;
  }
}

.prizes-block-3 {
  position: relative;
  min-height: 625px;
  background-image: url("../assets/img/landing-blue-rectangle.png");

  h2 {
    margin-top: 70px;
    color: $purple;
  }

  h3 {
    margin-bottom: 5px;
    font-size: 28px;
  }
}

.prizes-carousel {
  text-align: center;
  margin-top: 6.5%;
  width: 95%;
  margin-left: 5%;
}

body, html {
  overflow-x: hidden;
}

.merch {
  margin-top: 45px;
  text-align: center;

  //& > div:nth-child(2) > p {
  //  margin-top: 70px;
  //}

  p {
    font-size: 20px;
    font-weight: 500;
  }
}

.merch-price {
  display: inline-block;
  height: 45px;
  padding: 0 20px 0 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 45px;
  color: $purple;
  background-color: $white;
  border-radius: 50px;
  white-space: nowrap;
}

.certificates-carousel {
  margin-top: 70px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: $white;
}

.products {
  position: relative;
  height: 900px;
  margin-top: 100px;
  background-image: url("../assets/svg/landing-yellow-green-rectangle.svg");

  h1 {
    margin-bottom: 10px;
    padding: 50px 10% 0 10%;
  }

  .pink-thunderbolt-label {
    top: 60px;
    left: 4%;
  }

  .heart-label {
    bottom: 89px;
    right: 44%;
  }

  img {
    max-height: 350px;
  }
}

.product-carousel-1, .product-carousel-2 {
  display: inline-block;
  width: 50%;
  text-align: center;
  color: $white;
}

.background-label {
  position: absolute;
}

@media screen and (max-width: $xxxl) {
  .button-block {
    padding-right: 40px;
    //flex-direction: column;
    align-items: flex-end;
  }

  .steps li {
    &:nth-child(1) {
      margin-right: 20px;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:nth-child(3) {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 1520px) {
  .descriptor {
    height: 720px;
    background-position-x: 40%;
  }
}

@media screen and (max-width: 1420px) {
  .prizes-carousel img {
    max-height: 155px;
  }

  .prizes-carousel {
    margin-top: 8.5%
  }
}

@media screen and (max-width: $xxl) {
  .prizes-block-1, .prizes-block-2, .prizes-block-3 {
    h2 {
      font-size: 22px;
    }
  }

  .merch {
    margin-top: 20px;

    //& > div:nth-child(2) > p {
    //  margin-top: 58px;
    //}

    //img {
    //  width: 100%;
    //}
  }

  .prizes-carousel-block img {
    //width: 100%;
  }

  .certificates-carousel {
    margin-top: 50px;
    font-size: 18px;
    padding-bottom: 65px;

    img {
      width: 70%;
    }
  }
}

body,html {
  overflow-x: hidden;
}

@media screen and (max-width: $xl) {
  h1 {
    font-size: 40px;
  }

  .register-receipt, .tg-bot {
    font-size: 12px;
  }

  .register-receipt, .tg-bot {
    height: 60px;
    line-height: 60px;
  }

  .register-receipt::before {
    top: 9px;
  }

  .tg-bot::before {
    top: 12px;
  }

  .steps {
    margin-top: 80px;
    font-size: 20px;

    li {
      width: 280px;
      padding-top: 10px;
    }
  }

  .descriptor-carousel {
    margin-top: 30px;

    img {
      width: 80%;
    }
  }

  .what-to-do-block {
    img {
      width: 110px;
      height: 110px;
    }

    h2 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  .more-points {
    margin: 100px 5% 0 5%;
    padding: 0 30px 0 30px;
  }

  .want-more-points {
    font-size: 22px;
  }

  .more-points-link {
    height: 60px;
    font-size: 16px;
    line-height: 60px;
  }

  .prizes-block-1, .prizes-block-2, .prizes-block-3 {
    padding-top: 15px;
  }

  .prizes-block-1 {
    min-height: 500px;
  }

  .prizes-block-2 {
    min-height: 400px;
  }

  .prizes-block-3 {
    min-height: 400px;
  }

  .merch {
    margin-top: 10px;

    & > div:nth-child(2) > p {
      margin-top: 50px;
    }

    img {
      //width: 80%;
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  .merch-price {
    height: 35px;
    font-size: 14px;
    line-height: 35px;
  }

  .certificates-carousel {
    margin-top: 40px;
    font-size: 16px;
  }

  .products h1 {
    padding: 50px 20% 0 20%;
  }

  .prizes-block-3 {
    h2 {
      margin: 3.5% 0 1% 0;
    }

    h3 {
      font-size: 18px;
    }

    img {
      width: 60%;
    }

    p {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: $lg) {
  h1 {
    font-size: 30px;
  }

  .instruction {
    background-image: url("../assets/svg/landing-purple-rectangle-mob.svg");
  }

  .prizes-block-1 {
    background-image: url("../assets/svg/landing-yellow-rectangle-prize-mob.svg");
  }

  .prizes-block-2 {
    background-image: url("../assets/svg/landing-pink-rectanlge-prize-mob.svg");
  }

  .prizes-block-3 {
    background-image: url("../assets/svg/landing-blue-rectangle-prize-mob.svg");
  }

  .prizes-block-1, .prizes-block-2, .prizes-block-3 {
    margin: 0;
    padding: 0 1.5rem 0 1.5rem;
    background-size: cover;
  }

  .prizes-block-3 {
    img {
      width: auto;
    }
  }

  .descriptor {
    height: inherit;
    background-image: none;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: 100px;
    padding-top: 200px;
  }

  .descriptor-logo-mob {
    display: block;
    width: 100%;
  }

  .button-block {
    display: block;
    padding: 0;
  }

  .descriptor-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .landing-button {
      display: block;
      height: 50px;
      margin: 10px 0 10px 0;
      line-height: 50px;
      font-size: 16px;
    }

    .register-receipt::before {
      top: 4px;
    }

    .tg-bot::before {
      top: 7px;
    }

    .steps {
      align-items: flex-start;
      margin-top: 20px;
      font-size: 16px;

      li {
        margin: 0;
      }
    }

    .descriptor-carousel {
      padding-right: 0;
      text-align: center;
      padding-bottom: 25px;

      & > * {
        width: 100%;
      }
    }
  }

  .instruction {
    min-height: 769px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    h1 {
      margin: 0;
      padding-top: 40px;
    }

    .yellow-thunderbolt-label, .heart-label {
      display: none;
    }

    .yellow-thunderbolt-label-mob {
      display: block;
      position: absolute;
      width: 51px;
      height: 58px;
      top: 90px;
      left: 36px;
    }

    .more-points {
      margin: 40px 0 0 0;
      height: 300px;
      flex-direction: column;
      row-gap: 20px;
      background-image: url("../assets/svg/landing-white-rectangle-mob.svg");
      background-size: cover;
      text-align: center;

      .more-points-link {
        height: 65px;
        line-height: 32px;
        white-space: normal;
      }
    }
  }

  .prizes-block-1, .prizes-block-2, .prizes-block-3 {
    min-height: auto;

    h2 {
      padding: 40px 0 0 0;
      font-size: 24px;
    }
  }

  .prizes-block-1 {
    padding-bottom: 65px;
  }

  .prizes-block-3 {
    margin-top: -25px;
    padding-bottom: 60px;
  }

  .products {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 140px;
    box-sizing: border-box;
    margin: 0;
    height: auto;
    background-image: url("../assets/svg/landing-yellow-rectangle-mob.svg");

    h1 {
      padding: 40px 0 0 0;
      font-size: 30px;
    }

    .pink-thunderbolt-label {
      width: 62px;
      height: 87px;
      top: 242px;
      left: 39px;
    }

    .heart-label {
      width: 75px;
      height: 72px;
      top: 835px;
      right: 24px;
    }

    .product-carousel-1, .product-carousel-2 {
      margin-top: 60px;
      width: 100%;
      display: block;
    }
  }
}
</style>

<style>
.apm-btn-main {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 992px) {
  .prizes-carousel {
    text-align: center !important;
    margin-top: auto !important;
    width: auto !important;
    margin-left: auto !important;
    opacity: 1 !important;
  }

  .monthly-prizes {
    display: none;
  }
}

@media screen and (min-width: 993px) {
  .agile__slide .prizes-carousel-block {
    text-align: right;
  }

  .agile__slide .prizes-carousel-wrapper {
    text-align: center;
    float:right;
    width: 300px;
  }

  .agile__slide .prizes-carousel-block p {
    text-align: center;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block {
    text-align: left;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block p {
    text-align: center;
  }

  .agile__slide.agile__slide--active .prizes-carousel-block img {

  }

  .agile__slide.agile__slide--active .prizes-carousel-wrapper {
    width: 300px;
    text-align: center;
    float:left;
  }

  .agile__slide.agile__slide--active + .agile__slide + .agile__slide .prizes-carousel-wrapper {
    float:right;
  }

  .slide0 .agile__slide:nth-child(1) {
    opacity: 0.5;
  }

  .slide1 .agile__slide:nth-child(2) {
    opacity: 0.5;
  }

  .slide2 .agile__slide:nth-child(3) {
    opacity: 0.5;
  }

  .slide3 .agile__slide:nth-child(4) {
    opacity: 0.5;
  }

  .slide4 .agile__slide:nth-child(5) {
    opacity: 0.5;
  }

  .slide0 .agile__slide:nth-child(3) {
    opacity: 0.5;
  }

  .slide1 .agile__slide:nth-child(4) {
    opacity: 0.5;
  }

  .slide2 .agile__slide:nth-child(5) {
    opacity: 0.5;
  }

  .slide3 .agile__slide:nth-child(6) {
    opacity: 0.5;
  }

  .slide4 .agile__slide:nth-child(7) {
    opacity: 0.5;
  }

  .slide3 .agile__slides--cloned .agile__slide:nth-child(1) .prizes-carousel-wrapper {
    opacity: 0.5;
    float:right;
  }

  .slide4 .agile__slides--cloned .agile__slide:nth-child(2) .prizes-carousel-wrapper {
    opacity: 0.5;
    float:right;
  }

  .slide0 .agile__slide:nth-child(2) {
    opacity: 1;
  }

  .slide1 .agile__slide:nth-child(3) {
    opacity: 1;
  }

  .slide2 .agile__slide:nth-child(4) {
    opacity: 1;
  }

  .slide3 .agile__slide:nth-child(5) {
    opacity: 1;
  }

  .slide4 .agile__slide:nth-child(6) {
    opacity: 1;
  }
}

</style>